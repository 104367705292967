import { PureComponent } from "react";
import { withRouter } from "react-router-dom";

class ScrollIntoView extends PureComponent {
  componentDidMount = () => window.scrollTo(0, 0);

  componentDidUpdate = (prevProps) => {
    console.log(this.props.location, prevProps.location);
    const gtag = window.gtag;
    if (gtag) {
      gtag("config", "G-6JHRCSZMS3", {
        page_path: this.props.location.pathname + this.props.location.search,
      });
    }
    if (this.props.location.pathname == prevProps.location.pathname) {
      // window.scrollTo(450, 450);
    } else {
      window.scrollTo(0, 0);
    }
  };

  render = () => this.props.children;
}

export default withRouter(ScrollIntoView);

import React ,{ useState, useEffect,createContext} from "react";

export const SebedimContext = createContext();

const SebedimContextProvider = (props)=>{
    
    
    let localData;
    let localFav;
        const harytlar =localStorage.getItem("ChynarSebedim");
        const favHarytlar = localStorage.getItem("FavHarytlar");
    if(harytlar){
        localData = JSON.parse(harytlar);
    }
    
    else{
        localData = [];
    }
    if(favHarytlar){
        localFav = JSON.parse(favHarytlar);
    }else{
        localFav = [];
    }


    
  
    const [sebedim,setSebedim]=useState(localData);    
    const [favorites,setFavorites] = useState(localFav);



    const AddToFav = async(product)=>{
        console.log("addtoo",product);
        let id = product.id;
        let barmy=false;
              favorites.map((haryt)=>{
                if(haryt.id===product.id){
                   barmy=true;
                }
            });
            if(!barmy){   
                let harytlar = favorites;
                harytlar.push() ;        
                setFavorites([
                        ...favorites,
                            product
                    ]);
                }else{
                   let harytlar = favorites.filter((haryt,i)=>{
                    return product.id !== haryt.id;
                   });
                   setFavorites(harytlar);

                }
    }

    const  Increment = async(id,sany)=>{
        // let haryt;
        // let num ;
        // await sebedim.map((sebet,no)=>{
        //     if(sebet.id===id){
        //         haryt=sebet;
        //         num=no;
        //     }
            
        // return null;
        // });
        // if(sany){
        //     haryt.sany=haryt.sany+sany;
        // }else{
        //     haryt.sany=haryt.sany+1;
        // }
        
        // let sebet = []; 
        // await sebedim.map((obj,index)=>{
        //     if(index===num){
        //         sebet.push(haryt);
        //     }else{
        //         sebet.push(obj);
        //     }
            
        // return null;
        // })      
        //  setSebedim(
        //         sebet 
        // );
        let haryt;
        let num =-1;
        let marketIndex = -1;
        await sebedim.map((market,marketNo)=>{
            market?.products?.map((product,no)=>{
                if(product.id===id){
                    haryt=product;
                    num=no;
                    marketIndex=marketNo;
                }
            })
           
            
        return null;
        });
         if(sany){
            haryt.sany=haryt.sany+sany;
        }else{
            haryt.sany=haryt.sany+1;
        }
        
        let sebet = []; 
        await sebedim[marketIndex].products.map((obj,index)=>{
                if(index===num){
                    sebet.push(haryt);
                }else{
                    sebet.push(obj);
                }
                
            return null;
            })  
          
        let basket = sebedim;
        basket[marketIndex].products = sebet;
         setSebedim(
                [...basket]
        );
    }
    const  Decrement = async(id,sany)=>{
        let haryt;
        let num ;
        let marketIndex = -1;
        await sebedim.map((sebety,marketNo)=>{
            sebety?.products?.map((product,no)=>{
                if(product.id===id){
                    haryt=product;
                    num=no;
                    marketIndex=marketNo;
                }
            })
           
            
        return null;
        });
        haryt.sany=haryt.sany-1;
        
        let sebet = []; 
        await sebedim[marketIndex].products.map((obj,index)=>{
                if(index===num){
                    sebet.push(haryt);
                }else{
                    sebet.push(obj);
                }
                
            return null;
            })  
         
        let basket = sebedim;
        basket[marketIndex].products = sebet;
         setSebedim(
                [...basket]
        );
    }
    const AddTo = async(product,renk,razmer)=>{
        console.log("addtoo",product);
        let id = product.id;
        let barmy=false;
        let marketBamy = false;
        let marketIndex = -1;
        let marketId = product.Market.id;
        
        await sebedim?.map((market,i)=>{
            if(market.marketId === marketId){
                marketBamy=true;
                marketIndex=i;
            }
            market.products?.map((haryt)=>{
                if(haryt.id === id){
                    Increment(id);
                    barmy=true;
                }
            })
        });


           if(marketIndex ===-1 && !marketBamy && !barmy) {
                let sebetCopy = sebedim;
                sebetCopy.push({
                    marketId:marketId,
                    market:product.Market,
                    jemi:product.price,
                    products:[{
                        id:product.id,
                        baha:product.price,
                        MarketId:product.MarketId,
                        marketName:product.Market,
                        product,
                        sany:1,
                        renk,
                        razmer
                    }]
                    });
                    setSebedim([...sebetCopy]);
            }

            if(!barmy && marketIndex!==-1 ){   
                     let data = sebedim;
                     data[marketIndex]?.products.push({
                        id:product.id,
                        baha:product.price,
                        MarketId:product.MarketId,
                        marketName:product.Market,
                        product,
                        sany:1,
                        renk,
                        razmer
                     })
                     setSebedim([...data]);
                }
    }
    const AddToMany = async(product,sany)=>{
        let barmy=false;
        let id=product.id;
             await sebedim.map(async(haryt)=>{
                if(haryt.id===id){
                   Increment(id,sany);
                   barmy=true;
                }else{
                //    await AddTo(product);
                //    Increment(product.id,sany-1)

                }
                
        return null;
            });
            if(!barmy){   
                let harytlar = sebedim;
                harytlar.push() ;        
                    setSebedim([
                        ...sebedim,
                        {
                            id:product.id,
                            baha:product.product_price,
                            product,
                            sany:sany,
                            }
                    ]);
                }
    }
    const Remove = async(id)=>{
        let harytlar = [];
        let marketIndex = -1;
        let newHarytlar = [];
         sebedim.map((sebet,i)=>{
            harytlar = [];
             sebet?.products.map((haryt)=>{
                if(id===haryt.id){
                    marketIndex = i;
                }
             });
        });

        let data = sebedim;
        let newArray = data[marketIndex]?.products?.filter((haryt)=>{
            return haryt.id !==id
        })
        if(newArray?.length == 0){
            
        console.log("newArray",newArray)
        
        console.log("marketIndex",marketIndex)
            let newMarkets = []
            sebedim.filter((market,h)=>{
                if(h!=marketIndex){
                    newMarkets.push(market);
                }
            });
            
        console.log("newMarkets",newMarkets)
            setSebedim([...newMarkets]);
        }else{
            
            console.log("marketIndex",marketIndex)
            data[marketIndex].products = newArray;
            setSebedim([...data]);
            data = []
        }
       

    }
    const RemoveAllPro = async(id)=>{
        const harytlar = await sebedim.filter((haryt)=>{
            return id !== haryt.marketId;
        });
         setSebedim([...harytlar]);
   }

    const RemoveAll = async(id)=>{
        
         setSebedim([]);

    }
    const  Barlag = async(id,sany)=>{
        let haryt;
        let num ;
        await sebedim.map((sebet,no)=>{
            if(sebet.product.product_id===id){
                haryt=sebet;
                num=no;
            }
            
        return null;
        });
        console.log("sebedim barlag",sany,haryt)
        // if(haryt.sany!=sany){
        //     bool=true;
        // }
        haryt.sany=sany;
        
        let sebet = []; 
        await sebedim.map((obj,index)=>{
            if(index===num){
                sebet.push(haryt);
            }else{
                sebet.push(obj);
            }
            
        return null;
        })      
         setSebedim(
                sebet 
        );
        // return bool;
    }
    useEffect(()=>{
        
            localStorage.setItem("ChynarSebedim",JSON.stringify(sebedim));
        
    },[sebedim]);

    useEffect(()=>{
        
        localStorage.setItem("FavHarytlar",JSON.stringify(favorites));
    
    },[favorites]);

  
   
    let [dil,setDil]=useState();
    useEffect(()=>{
            let dilData = localStorage.getItem("chynarDil");
        if(dilData){
            setDil(JSON.parse(dilData));
        }else{
            setDil("TM");
        }
    },[])

    const ChangeDil = (event)=>{
            setDil(event);
            localStorage.setItem("chynarDil",JSON.stringify(event));
    }

    return(
        <SebedimContext.Provider value={{sebedim,dil,favorites,Increment,Decrement,AddTo,AddToMany,Remove,RemoveAll,RemoveAllPro,ChangeDil,Barlag,AddToFav}}>
            {props.children}
        </SebedimContext.Provider>
    );
};
 

export default SebedimContextProvider;
import React, {useContext} from "react";
import { Link } from "react-router-dom";
import { SebedimContext } from "../context/Sebedim";

import "../css/style.css";

import success from "../img/success.png";

const Success = ()=>{
  const {dil} = useContext(SebedimContext);
    return (
    <main class="main">
    <div class="main-second-box main-second-box__big">
      <div class="success">
        <section class="success__bred-crumb bred-crumb">
          <div class="bred-crumb__container __container">
            {/* <div class="bred-crumb__links">
              <a href="#" class="bred-crumb__link">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                >
                  <path
                    id="noun_Home_2102808"
                    d="M7.028,12.906l.327-.276v7.745A.625.625,0,0,0,7.98,21H19.02a.625.625,0,0,0,.625-.625V12.63l.327.276a.625.625,0,0,0,.806-.955l-3-2.533V7.039a.625.625,0,1,0-1.25,0V8.363L13.9,6.147a.625.625,0,0,0-.806,0l-6.875,5.8a.625.625,0,1,0,.806.955ZM12.1,19.75V15.238H14.9V19.75ZM13.5,7.443l4.895,4.132V19.75H16.147V14.613a.625.625,0,0,0-.625-.625H11.478a.625.625,0,0,0-.625.625V19.75H8.605V11.575Z"
                    transform="translate(-6 -6)"
                  />
                </svg>
              </a>
              <a href="#" class="bred-crumb__link">Çynar market </a>
              <a href="#" class="bred-crumb__link">Öý harytlary</a>
              <a href="#" class="bred-crumb__link"
                >Dove Nutritive Solutions
              </a>
              <a
                href="#"
                class="bred-crumb__link bred-crumb__link--disabled"
                >Sebet</a
              >
              <a href="#" class="bred-crumb__link">Töleg görnüşi</a>
            </div> */}
          </div>
        </section>
        <div class="success__container __container">
          <div class="success__img">
            <img src={success} alt="" />
          </div>
          <div class="success__text">
            <h3>{dil==="TM"?"Siziň sargydyňyz kabul edildi!":(dil==="RU"?"Ваш заказ принят!":"Your order has been accepted!")}</h3>
            <p>
              {dil==="TM"?"Biziň kurýerymyz siziň bilen gysga wagtyň içinde habarlaşar":(dil==="RU"?"Наш курьер свяжется с вами в ближайшее время":"Our courier will contact you shortly")}
            </p>
          </div>
          <Link to="/home" class="success__link">{dil==="TM"?"Söwda geç":(dil==="RU"?"Ходить по магазинам":"Go shopping")}</Link>
        </div>
      </div>
    </div>
  </main>
  )
}

export default Success;
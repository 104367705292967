import React, { lazy, Suspense } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import history from "./history";
import {
  Basket,
  BrendProducts,
  Brends,
  Favourites,
  FirstPage,
  Forget,
  Home,
  Login,
  Market,
  NotFound,
  Product,
  Profile,
  Sargydym,
  SignUp,
  KategoryProducts,
  Toleg,
  SearchProducts,
  Contact,
  SubKategoryProducts,
  Delivery,
  About,
  Adds,
  Privacy,
  MarketSub,
  MarketCat,
} from "../pages/index";
import ScrollIntoView from "./ScrollIntoView";
import { Loading } from "../components/loading";
import PublicRoute from "./PublicRoute";
import Success from "../components/success";
// import usePageTracking from "../utils/usePageTracking";

const PrivateRoute = lazy(() => import("./PrivateRoute"));
// const PublicRoute = lazy(() => import("./PublicRoute"));
const App = () => {
  // usePageTracking();
  return (
    <BrowserRouter history={history}>
      <ScrollIntoView>
        <Suspense fallback={<Loading />}>
          <Switch>
            {/* <PrivateRoute
              restricted={false}
              component={FirstPage}
              path="/"
              exact
            />
             <PrivateRoute
              restricted={true}
              component={FirstPage}
              path="/"
              exact
            /> */}
            <Route path="/" exact component={FirstPage} />
            <Route path="/success" exact component={Success} />
            <PrivateRoute
              restricted={true}
              component={Home}
              path="/home"
              exact
            />
            <PrivateRoute
              restricted={true}
              component={Market}
              path="/market/:idM"
              exact
            />
            <PrivateRoute
              restricted={true}
              component={MarketCat}
              path="/market/:idM/:idC"
              exact
            />
            <PrivateRoute
              restricted={true}
              component={MarketSub}
              path="/market/:idM/:idC/:idS"
              exact
            />
            <PrivateRoute
              restricted={true}
              component={Favourites}
              path="/favourites"
              exact
            />
            <PrivateRoute
              restricted={true}
              component={Product}
              path="/product/:id"
              exact
            />
            <PrivateRoute
              restricted={true}
              component={Basket}
              path="/basket"
              exact
            />
            <PublicRoute
              restricted={true}
              component={Profile}
              path="/profile"
              exact
            />
            <PrivateRoute
              restricted={true}
              component={Sargydym}
              path="/sargydym/:id"
              exact
            />
            <PrivateRoute
              restricted={true}
              component={Toleg}
              path="/toleg/:id/:sum"
              exact
            />
            <PrivateRoute
              restricted={true}
              component={SignUp}
              path="/sign-up"
              exact
            />
            <PrivateRoute
              restricted={true}
              component={Login}
              path="/login"
              exact
            />
            <PrivateRoute
              restricted={true}
              component={Forget}
              path="/forget"
              exact
            />
            <PrivateRoute
              restricted={true}
              component={Brends}
              path="/brends"
              exact
            />
            <PrivateRoute
              restricted={true}
              component={BrendProducts}
              path="/brand/:brandId"
              exact
            />
            <PrivateRoute
              restricted={true}
              component={KategoryProducts}
              path="/kategory/:id"
              exact
            />
            <PrivateRoute
              restricted={true}
              component={SubKategoryProducts}
              path="/subkategory/:id"
              exact
            />
            <PrivateRoute
              restricted={true}
              component={SearchProducts}
              path="/search/:product_name"
              exact
            />
            <PrivateRoute
              restricted={true}
              component={Contact}
              path="/contact"
              exact
            />
            <PrivateRoute
              restricted={true}
              component={Delivery}
              path="/delivery"
              exact
            />
            <PrivateRoute
              restricted={true}
              component={About}
              path="/about"
              exact
            />
            <PrivateRoute
              restricted={true}
              component={Privacy}
              path="/privacy"
              exact
            />
            <PrivateRoute
              restricted={true}
              component={Adds}
              path="/reklama/:id"
              exact
            />

            <PrivateRoute component={Home} path="*" />
            {/* <Route path="*" component={Home} /> */}
          </Switch>
        </Suspense>
      </ScrollIntoView>
    </BrowserRouter>
  );
};

export default App;

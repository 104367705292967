import React, { useContext, useEffect, useState } from "react";

import "../../css/style.css";
import { Carousel } from "antd";
import "antd/dist/antd.css";

import serpay from "../../img/main/serpay.png";
import { Link, useHistory } from "react-router-dom";
import { axiosInstance, BASE_URL } from "../../utils/axiosIntance";
import { SebedimContext } from "../../context/Sebedim";
const Home = () => {
  const history = useHistory();
  const { dil } = useContext(SebedimContext);
  const [sliders, setSliders] = useState([]);
  const [markets, setMarkets] = useState([]);
  useEffect(() => {
    getMarkets();
    GetSliders();
  }, []);
  const getMarkets = () => {
    let welayatId = localStorage.getItem("welayatId");
    axiosInstance
      .get("/api/kategoryOfMarkets/" + welayatId)
      .then((data) => {
        setMarkets(data.data);
        // console.log("markets",data.data);
        localStorage.setItem("MarketId", data.data[0].Markets[0].id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const GoToMarket = (id) => {
    localStorage.setItem("MarketId", id);
    history.push({
      pathname: "/market" + id,
    });
  };

  const GetSliders = () => {
    axiosInstance
      .get("/api/sliders")
      .then((data) => {
        setSliders(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <main className="main">
      <section className="main-slider">
        <div className="main-slider__container __container">
          <div className="main-slider__slides __js-slider">
            <Carousel autoplay arrows>
              {/* {banners.map((banner)=>{
                  return ( */}

              {sliders?.map((slider, i) => {
                return (
                  <article key={"key" + i} className="main-slider__slide">
                    <div
                      onClick={() => {
                        history.push({ pathname: "/kategory/" + slider.link });
                      }}
                      className="main-slider__img "
                    >
                      {dil == "TM" ? (
                        <img
                          height="540px"
                          src={BASE_URL + "/" + slider.title_tm}
                          alt="surat"
                        />
                      ) : dil == "RU" ? (
                        <img
                          height="540px"
                          src={BASE_URL + "/" + slider.title_ru}
                          alt="surat"
                        />
                      ) : (
                        <img
                          height="540px"
                          src={BASE_URL + "/" + slider.title_en}
                          alt="surat"
                        />
                      )}
                    </div>
                  </article>
                );
              })}
              {/* <article className="main-slider__slide">
                            <div className="main-slider__img">
                                <img src={serpay} alt=""/>
                            </div>
                        </article> */}

              {/* )
                })} */}
            </Carousel>
          </div>
        </div>
      </section>
      <section className="main-company-block">
        <div className="main-company-block__container __container">
          <section className="main-company-block__row">
            {/* <Link
                to="/market"
                className="main-company-block__box main-company-block__box--big"
              >
                <img src={serpay} alt="" />
              </Link>
              <Link to="/market" className="main-company-block__box">
                <img src={serpay} alt="" />
              </Link>
              <Link to="/market" className="main-company-block__box">
                <img src={serpay} alt="" />
              </Link>
              <Link
                to="/market"
                className="
                  main-company-block__box main-company-block__box--full-width
                "
              >
                <img src={serpay} alt="" />
              </Link> */}
            {markets &&
              markets?.map((market, i) => {
                return (
                  <>
                    {/* <h1 style={{width:"100%",display:"block"}}>{market.name_tm}</h1> */}
                    {/* <br></br> */}
                    {market?.Markets?.map((market1, j) => {
                      return (
                        market1.active && (
                          <Link
                            key={"key2iki" + i + j}
                            onClick={() => GoToMarket(market1.id)}
                            to={`/market/${market1.id}`}
                            className={`main-company-block__box ${
                              j === 0 &&
                              i === 0 &&
                              "main-company-block__box--big"
                            }`}
                          >
                            <img src={BASE_URL + "/" + market1.surat} alt="" />
                          </Link>
                        )
                      );
                    })}
                  </>
                );
              })}
          </section>
        </div>
      </section>
    </main>
  );
};

export default Home;

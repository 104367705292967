import { lazy } from "react";

export const Test = lazy(() => import("./test"));
export const NotFound = lazy(() => import("./404/404"));
export const Home = lazy(() => import("./home/home"));
export const Market = lazy(() => import("./markets/market"));
export const MarketCat = lazy(() => import("./markets/marketCategory"));
export const MarketSub = lazy(() => import("./markets/marketSubCategory"));
export const Favourites = lazy(() => import("./favourites/favourites"));
export const Product = lazy(() => import("./product/product"));
export const Basket = lazy(() => import("./basket/basket"));
export const Profile = lazy(() => import("./profile/profile"));
export const Sargydym = lazy(() => import("./profile/Sargydym"));
export const Toleg = lazy(() => import("./basket/toleg"));
export const SignUp = lazy(() => import("./login/signUp"));
export const Login = lazy(() => import("./login/login"));
export const Forget = lazy(() => import("./login/forget"));
export const FirstPage = lazy(() => import("./home/firstPage"));
export const Brends = lazy(() => import("./brends/brends"));
export const BrendProducts = lazy(() => import("./brends/brandsProducts"));
export const KategoryProducts = lazy(() =>
  import("./kategoryProducts/kategoryProducts")
);
export const SubKategoryProducts = lazy(() =>
  import("./kategoryProducts/subKategoryProducts")
);
export const SearchProducts = lazy(() => import("./search/search"));
export const Contact = lazy(() => import("./contact/contact"));
export const Delivery = lazy(() => import("./about/eltipbermek"));
export const About = lazy(() => import("./about/about"));
export const Privacy = lazy(() => import("./about/privacy"));
export const Adds = lazy(() => import("./adds/fullScreenAdds"));


import './App.css';
import SebedimContextProvider from './context/Sebedim';

import Routes from "./routes/routes";
function App() {
  // console.log = () => {};
  console.error = () => {};
  console.warning = () => {};
  console.warn = () => {};
  console.info = () => {};
  return (
    <div className="App">
      <SebedimContextProvider>
         <Routes/>
      </SebedimContextProvider>
     
    </div>
  );
}

export default App;
